import React, { useEffect, useState, useRef } from 'react';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { useRunEngine } from '../../utilities/src/hooks/useRunEngine';
import { useBlockHelpers } from '../../utilities/src/hooks/useBlockHelpers';
import { getStorageData } from '../../../framework/src/Utilities';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { config } from './config.web';

import StripePaymentsView from './StripePaymentsView.web';

// Customizable Area Start
import { CardElement } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
// Customizable Area End

export interface ViewProps {
	// Customizable Area Start
	testID: string;
	stripePromise: any;
	stripeClientSecret: string | undefined;
	errorString: string | undefined;
	setOrderNumber: (_: any) => void;
	orderNumber: number | undefined;
	actionResult: string | undefined;
	stripeInitialised: boolean;
	isInStripeCallback: boolean;
	onHandleSubmit: (event: React.FormEvent<HTMLFormElement>, stripe: Stripe | null, stripeElements: StripeElements | null,directPayment:any,isChecked:any) => void;
	submitOrderNumberButtonViewProps: { value: string };
	submitPaymentButtonViewProps: { value: string };
	loadingViewProps: { value: string };
	orderIdViewProps: { value: string };
	stripeMessageViewProps: { successValue: string; errorValue: string };
	navigation:any;
	listResponse:any;
	setshowElement:any;
	handleDelete:any;
	// Customizable Area End
}

export interface ControllerProps {
	navigation: any;
	id: string;
	// Customizable Area Start
	// Customizable Area End
}

const subscribedMessages = [
	// Customizable Area Start
	MessageEnum.RestAPIResponceMessage,
	MessageEnum.SessionResponseMessage,
	// Customizable Area End
];

const StripePayments = ({ navigation, id }: ControllerProps) => {
	// Customizable Area Start
	const getPaymentMethodsCallId = useRef<string>('');
	const getOrderMethodsCallId = useRef<string>('');
	const getListCallId = useRef<string>('');
	const confirmPaymentId=useRef<string>('')
	const deletePaymentId=useRef<string>('')
	const postPaymentId=useRef<string>('')
	const bookSlotCallId = useRef<string>('');
	// Customizable Area End

	// Customizable Area Start
	const params = new URLSearchParams(window.location.search);
	const returnedPaymentIntentClientSecret = params.get('payment_intent_client_secret');
	const isInStripeCallback = Boolean(returnedPaymentIntentClientSecret);
	const [orderNumber, setOrderNumber] = useState<any>(false);
	const [userAppAuthenticationToken, setUserAppAuthenticationToken] = useState<string | undefined>(undefined);
	const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string | undefined>(undefined);
	const [stripeClientSecret, setStripeClientSecret] = useState<any>(undefined);
	const [stripeCustomerId, setStripeCustomerId] = useState<string | undefined>(undefined);
	const [stripeInitialised, setStripeInitialised] = useState<boolean>(false);
	const [stripeActionResultMessage, setStripeActionResultMessage] = useState<string | undefined>(undefined);
	const [errorString, setErrorString] = useState<string | undefined>(undefined);
	const [orderResponse, setOrderResponse] = useState<any>(undefined);
	const [listResponse, setListResponse] = useState<any>([]);
	const [showElement, setshowElement] = useState<boolean>(false);

	// Customizable Area End

	// Customizable Area Start
	const { sendBlockMessage, sendNetworkRequest, setReceiveCallback, subscribe, unsubscribeFromMessage } =
		useRunEngine();

	const { extractNetworkResponse } = useBlockHelpers();

	const stripePromise = loadStripe(config.stripeKey); // Replace with your actual public key

	const onBookSlots = async () => {
	
		const token = await getStorageData('authToken');
		setUserAppAuthenticationToken(token);
		const body = JSON.parse(localStorage.getItem("noneSensitiveDataBooking") ?? "{}");

		if (token) {
			const response = sendNetworkRequest(
				bookSlotCallId,
				config.createPaymentIntentMethod,
				`${config.endPointApiBookedSlot}`,
				{
					'Content-Type': config.creatPaymentIntentApiContentType,
					token: token,
				},
				body
			);

			return response;
		}
	
};	

	const getToken = async () => {
	
			const token = await getStorageData('authToken');
			setUserAppAuthenticationToken(token);
	
			if (token) {
				// Await the first network request
				await getList();
				const order_type = window.location?.pathname.includes("Player") ? "lesson_purchase" : "in_app_purchase";
				// Await the second network request
				const response = sendNetworkRequest(
					getOrderMethodsCallId,
					config.createPaymentIntentMethod,
					`${config.createorderIntentEndpoint}?order_type=${order_type}&${window.location?.pathname.includes("Player") ? "lesson_package_id" : "subscription_id"}=${window.location.pathname.split('/')?.[2]}`,
					{
						'Content-Type': config.creatPaymentIntentApiContentType,
						token: token, // Use the retrieved token here
					}
				);
	
				// Optional: Handle the response if needed
				return response;
			}
		
	};	
	  

	const getList = async() => {
		const authToken = await getStorageData('authToken');
		if(authToken){
		 sendNetworkRequest(
			getListCallId,
			'GET',
			`${config.getListEndpoint}`,
			{
			  'Content-Type': config.creatPaymentIntentApiContentType,
			  token: authToken,
			}
		  );}
	};

	useEffect(() => {
		if (orderResponse) {
			createPaymentIntent()
		}
	}, [orderResponse])

	
	// Customizable Area End

	const receive = (from: string, message: Message) => {
		// Customizable Area Start
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
			if (apiRequestCallId === getPaymentMethodsCallId.current || from === 'UNIT_TEST') {
				handleGetPaymentMethods(responseJson);
			}
			if (apiRequestCallId === getOrderMethodsCallId.current || from === 'UNIT_TEST') {
				setOrderResponse(responseJson)
			}
			if (apiRequestCallId === getListCallId.current || from === 'UNIT_TEST') {
				setListResponse(responseJson)
			}
			handleReceive(apiRequestCallId, from);
		}
		// Customizable Area End
	};

	// Customizable Area Start
	const handleReceive = (apiRequestCallId: any, from: any) => {
		if (apiRequestCallId === confirmPaymentId.current || from === 'UNIT_TEST') {
			handlePaymentSuccess(from);
		}
		if (apiRequestCallId === deletePaymentId.current || from === 'UNIT_TEST') {
			toast.success("Delete Card Successfully!");
			getList()
		}
		if (apiRequestCallId === bookSlotCallId.current || from === 'UNIT_TEST') {
			navigation.navigate("ConfirmScheduling");
		}
		if (apiRequestCallId === postPaymentId.current || from === 'UNIT_TEST') {
			toast.success("Save Card Successfully!");
			setTimeout(() => {
				window.location.reload()
			},3000);
		}
	}
	const handlePaymentSuccess = (from: string) => {
		toast.success("Payment Successfully!");
		if (window.location?.pathname.includes("Player") || from === "UNIT_TEST") {
		onBookSlots();
		} else { 
		setTimeout(() => {
			window.location.href=`${window.location.origin}/UserProfile`
		},4000);
	}
	}

	const handleGetPaymentMethods = (responseJson: any) => {
		setStripeClientSecret(responseJson.data?.attributes?.client_secret);
		setStripePaymentIntentId(responseJson.data?.attributes.payment_intent_id);
		setStripeCustomerId(responseJson.data?.attributes.customer_id);
	}

	const createPaymentIntent = async () => {

		sendNetworkRequest(
			getPaymentMethodsCallId,
			config.createPaymentIntentMethod,
			config.createpaymentIntentEndpoint,
			{
				'Content-Type': config.creatPaymentIntentApiContentType,
				token: userAppAuthenticationToken,
			},
			{
				"payment_intent": {
					"order_id": orderResponse.data.id
				}
			}
		);
	};

	const handleDelete = async (e:any,data:any) => {
		e.preventDefault()
		sendNetworkRequest(
			deletePaymentId,
			'DELETE',
			`${config.getListEndpoint}/${data.id}`,
			{
				'Content-Type': config.creatPaymentIntentApiContentType,
				token: userAppAuthenticationToken,
			}
		);
	};
	// Customizable Area End

	useEffect(() => {
		setReceiveCallback(receive);
		subscribedMessages.forEach((message) => subscribe(message));
		// Customizable Area Start
		getToken();	
		
		// Customizable Area End
		return () => {
			subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
		};
	}, []);

	// Customizable Area Start
	
	useEffect(() => {
		if (showElement && stripeClientSecret) {
			setStripeInitialised(true);
		}
	}, [showElement,stripeClientSecret]);
	// Customizable Area End

	// Customizable Area Start
	const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>, stripe: Stripe | null, stripeElements: StripeElements | null, directPayment: any, isChecked: any) => {
		 
		// Check if the Stripe instance and Elements are available
		if (stripeElements == null || stripe == null) {
			return;
		}
		if (Object.keys(directPayment).length == 0) {
			const cardElement = stripeElements.getElement(CardElement);
			if (cardElement == null) {
				return;
			}
			const {token }: any = await stripe.createToken(cardElement);			

			sendNetworkRequest(
				postPaymentId,
				config.createPaymentIntentMethod,
				config.getListEndpoint,
				{
					'Content-Type': config.creatPaymentIntentApiContentType,
					token: userAppAuthenticationToken,
				},
				{
					"payment_method": {
						"token": token?.id??'',
						"default": isChecked
					}
				}
			);		
			
		}else{
		const { error: submitError } = await stripeElements.submit();
		if (submitError) {
			return;
		}

		const result: any =await stripe.confirmCardPayment(stripeClientSecret, {
				payment_method: directPayment.id, 
			});

		sendNetworkRequest(
			confirmPaymentId,
			config.createPaymentIntentMethod,
			config.createConfirmEndpoint,
			{
				'Content-Type': config.creatPaymentIntentApiContentType,
				token: userAppAuthenticationToken,
			}, {
			"payment_intent_id": stripePaymentIntentId,
			"order_id": orderResponse.data.id,
			"account_id": orderResponse.data.attributes.account_id,
			"payment_method_id": result.paymentIntent.payment_method,
			"stripe_payment_intent": {
				"amount": result.paymentIntent.amount,
				"currency": result.paymentIntent.currency,
				"id": result.paymentIntent.id,
				"paymentMethodId": result.paymentIntent.payment_method,
				"status": result.paymentIntent.status,
			}
		}

		);		

	}
		
	};
	// Customizable Area End

	// Customizable Area Start
	const orderIdViewProps = {
		value: config.orderId,
	};

	const submitOrderNumberButtonProps = {
		value: config.submitText,
	};

	const submitPaymentButtonProps = {
		value: config.submitText,
	};

	const loadingViewProps = {
		value: config.loading,
	};

	const stripeMessageViewProps = {
		successValue: config.stripeSuccessMessage,
		errorValue: config.stripeErrorMessage,
	};
	// Customizable Area End

	const viewProps: ViewProps = {
		testID: id,
		// Customizable Area Start
		errorString,
		stripePromise: stripePromise,
		stripeClientSecret: stripeClientSecret,
		setOrderNumber: setOrderNumber,
		orderNumber: orderNumber,
		actionResult: stripeActionResultMessage,
		stripeInitialised: stripeInitialised,
		isInStripeCallback: isInStripeCallback,
		onHandleSubmit: onHandleSubmit,
		submitOrderNumberButtonViewProps: submitOrderNumberButtonProps,
		submitPaymentButtonViewProps: submitPaymentButtonProps,
		loadingViewProps: loadingViewProps,
		orderIdViewProps: orderIdViewProps,
		stripeMessageViewProps: stripeMessageViewProps,
		navigation,
		listResponse,
		setshowElement,
		handleDelete,
		// Customizable Area End
	};

	return <StripePaymentsView {...viewProps} />;
};

export default StripePayments;
