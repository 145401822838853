import { Box, Dialog, DialogActions, DialogContent, Divider, Drawer, IconButton, InputAdornment, Menu, MenuItem, TextField, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { BlockComponent } from "../../framework/src/BlockComponent";

interface Props {
  profile: any;
  currentPath?: string;
  navigation: any;
  onChangeProfile?: (value: any) => void;
}

interface S {
  isOpen: boolean;
  isDropdownOpen: boolean;
  isDropdownDrawerOpen: boolean;
  anchorEl: any;
  profile: any;
  isDeleteAccountModalOpen: boolean;
  password: string;
  isPasswordVisible: boolean;
  passwordError: string;
}

interface SS {
  id: any;
}

const HomeLayoutStyle = styled("div")({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  display: "flex",
  flexDirection: "column",
  width: "100vw",
  minHeight: "100vh",
  padding: 0,
  margin: 0,
  fontFamily: "Urbanist,sans-serif",
  boxSizing: "border-box",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
},);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});


const Header = styled('header')({
  minHeight: 70,
  display: 'flex',
  background: "linear-gradient(to top, #2F7735 18.67%, #1A7A63 98.9%);",
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingInline: '100px',

  "& img:first-child": {
    width: '132px',
    height: '32px'
  },

  "@media (max-width: 960px)": {
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    paddingInline: '30px',
  },

  "& .triangle": {
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: "5px solid white",
  }
});

const Footer = styled('footer')({
  backgroundColor: '#C0C7CF',
  textAlign: 'center',
  paddingBlock: '10px',
  width: '100%',
  height: 'min-content',
  fontSize: '12px',
  fontWeight: 500,
  color: "#333333"
});

const Button = styled('button')({
  border: '1px solid #2F7735',
  borderRadius: '8px',
  fontSize: "15px",
  width: "150px",
  height: '56px',
  color: '#2F7735',
  fontWeight: 600,
  fontFamily: "Urbanist, sans-serif",
  textTransform: "none",
  backgroundColor: "white",
  cursor: "pointer",

  "@media (max-width: 600px)": {
    width: "120px",
    height: '50px',
  },
});

const Nav = styled('nav')({
  width: '65%',

  "@media (max-width: 1280px)": {
    width: '75%',
  },

  "@media (max-width: 1100px)": {
    justifyContent: 'flex-end',
    display: "flex"
  },

  "& ul": {
    listStyleType: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: "100%",
    alignItems: "center",

    "@media (max-width: 1100px)": {
      display: 'none',
    },
  },

  "& .hamburgerMenu": {
    width: "32px",
    height: "32px",
    cursor: "pointer",

    "@media (min-width: 1100px)": {
      display: 'none',
    },
  },

  "& ul li a": {
    textDecoration: 'none',
    color: 'white',
    fontSize: "14px",
    fontWeight: 500
  },
  "& .navigationTitle": {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Urbanist, sans-serif",
  },
  "& .navigationTitleDrawer": {
    fontFamily: "Urbanist, sans-serif",
    display: "flex",
    flexDirection: 'row',
    paddingLeft: "30px",
    fontSize: "14px"
  },
});

export default class HomeLayout extends BlockComponent<Props, S, SS> {
  apiDeleteAccountCallId: string = "";
  apiGetProfileApiCallId: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isOpen: false,
      isDropdownOpen: false,
      anchorEl: null,
      profile: window.localStorage.getItem('profile'),
      password: "",
      isDeleteAccountModalOpen: false,
      isPasswordVisible: false,
      passwordError: "",
      isDropdownDrawerOpen: false,
    };

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIResponse(message);
    }
  }

  async handleAPIResponse(message: Message) {
    const apiRequestCallIdMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallIdMessage === this.apiGetProfileApiCallId) {
      this.handleGetProfileCallId(responseJson, errorResponse);
    }

    if (apiRequestCallIdMessage === this.apiDeleteAccountCallId) {
      if (responseJson?.meta?.message === "Account deleted") {
        this.setState({ isDeleteAccountModalOpen: false })
        await removeStorageData('authToken');
        this.props.navigation.navigate("EmailAccountLogin");
      } else {
        this.setState({ passwordError: responseJson?.meta?.message })
      }
    }
  }

  handleGetProfileCallId(responseJson: any, errorResponse: any) {
    if (responseJson && responseJson.data) {
      window.localStorage.setItem('profile', responseJson.data);
      this.setState({ profile: responseJson.data })
      this.props?.onChangeProfile?.(responseJson.data)
    }
  }

  async componentDidMount() {
    try {
      const token = await getStorageData('authToken');
      if (token) {
        this.getProfile(token);
      } else if (!['TermsConditionsDetail', 'TermsConditions', 'PrivacyPolicy'].includes(this.props.currentPath ?? "")) {
        this.props.navigation.navigate("EmailAccountLogin");
      }
    } catch { }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props?.profile?.attributes?.role === "instructor" && this.props.currentPath === "/") {
      this.props.navigation.navigate("MyBookings");
    }
    if (!this.state.profile && this.props.profile) {
      this.setState({ profile: this.props.profile });
    }
  }

  getProfile = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const profileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetProfileApiCallId = profileRequestMessage.messageId;

    profileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    profileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/profiles/get_profile`
    );

    profileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(profileRequestMessage.id, profileRequestMessage);
  }


  onDeleteAccount = async () => {
    if (!this.state.password) {
      this.setState({ passwordError: "Password is required" });
      return;
    }

    const token = await getStorageData('authToken');

    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteAccountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/profiles/account_delete?password=${this.state.password}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  render() {
    const isShowNav = !window.location?.pathname?.includes("shareCoachProfile");

    return (
      <ThemeProvider theme={theme}>
        <HomeLayoutStyle>
          <Header>
            <img src={require("./logo.png")} style={{ cursor: "pointer" }} onClick={() => { this.props?.profile?.attributes?.role === "instructor" ? this.props.navigation?.navigate('MyBookings') : this.props.navigation?.navigate('Home') }} />
            {isShowNav &&
              <Nav>
                <ul>
                  {this.state?.profile?.attributes?.role === "player" && <li>
                    <span className="navigationTitle" style={{ fontWeight: this.props.currentPath === "/" ? 700 : 500, cursor: "pointer", color: "white", }} onClick={() => { this.props.navigation?.navigate('Home') }}>Home</span>
                  </li>}
                  <li><span className="navigationTitle" style={{ cursor: "pointer", color: "white", fontWeight: this.props.currentPath === "MyLessons" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('MyLessons')}>My Lessons</span></li>
                  <li><span className="navigationTitle" style={{ cursor: "pointer", color: "white", fontWeight: this.props.currentPath === "MyBookings" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('MyBookings')}>My Bookings</span></li>
                  <li><span className="navigationTitle" style={{ cursor: "pointer", color: "white", fontWeight: this.props.currentPath === "ContactUs" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('ContactUs')}>Contact</span></li>
                  <li><span className="navigationTitle" style={{ cursor: "pointer", color: "white", fontWeight: this.props.currentPath === "TermsConditionsDetail" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('TermsConditionsDetail')}>Terms & Conditions</span></li>
                  <li><span className="navigationTitle" style={{ cursor: "pointer", color: "white", fontWeight: this.props.currentPath === "PrivacyPolicy" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('PrivacyPolicy')}>Privacy Policy</span></li>
                  {this.state?.profile?.attributes?.role === "instructor" && <li><span className="navigationTitle" style={{ cursor: "pointer", color: "white", fontWeight: this.props.currentPath === "Reviews" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('Reviews', { id: this.state.profile.attributes.account_id })}>My Reviews</span></li>}
                  {this.state.profile && <li onClick={(event) => { this.setState({ isDropdownOpen: true, anchorEl: event.currentTarget }); }} style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                    position: "relative",
                    cursor: "pointer",
                    minWidth: "160px"
                  }}>
                    <img src={this.state.profile?.attributes?.photo ?? require("./default_avatar.png")} style={{ width: "32px", height: "32px", borderRadius: "50%" }} />
                    <Typography variant="body1" style={{ fontSize: "13px", fontWeight: 600, color: "white", maxWidth: "100px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      {`${this.state.profile?.attributes?.first_name ?? ""} ${this.state.profile?.attributes?.last_name ?? ""}`}
                    </Typography>
                    <div className="triangle" />
                  </li>}

                  <Menu
                    style={{
                      marginTop: "55px",
                      marginLeft: "0px"
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        width: '161px',
                      },
                    }}
                    anchorEl={this.state.anchorEl}
                    open={this.state.isDropdownOpen}
                    onClose={() => { this.setState({ isDropdownOpen: false }) }}
                  >
                    <MenuItem onClick={async () => {
                      this.props.navigation.navigate("UserProfile")
                    }}
                      style={{
                        fontFamily: "Urbanist, sans-serif",
                        display: "flex",
                        flexDirection: 'row',
                        paddingLeft: "30px",
                        fontSize: "14px"
                      }}
                    >Profile</MenuItem>
                    <MenuItem onClick={async () => {
                      this.props.navigation.navigate("Chat")
                    }}
                      style={{
                        fontFamily: "Urbanist, sans-serif",
                        display: "flex",
                        flexDirection: 'row',
                        paddingLeft: "30px",
                        fontSize: "14px"
                      }}
                    >Chats</MenuItem>
                    {this.state.profile?.attributes?.role === "instructor" &&
                      <MenuItem onClick={async () => {
                        this.props.navigation.navigate("MyVideos")
                      }}
                        style={{
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px"
                        }}

                      >My Videos</MenuItem>
                    }
                    {this.state.profile?.attributes?.role === "instructor" &&
                      <MenuItem onClick={async () => {
                        this.props.navigation.navigate("MyStudents")
                      }}
                        style={{
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px"
                        }}

                      >My Students</MenuItem>
                    }
                    {this.state.profile?.attributes?.role === "instructor" &&
                      <>
                      <MenuItem onClick={async () => {
                        this.props.navigation.navigate("LessonPackage")
                      }}
                        style={{
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px"
                        }}

                      >Lesson Package</MenuItem>
                        <MenuItem onClick={async () => {
                          this.props.navigation.navigate("Subscriptionbilling2")
                        }}
                          style={{
                            fontFamily: "Urbanist, sans-serif",
                            display: "flex",
                            flexDirection: 'row',
                            paddingLeft: "30px",
                            fontSize: "14px"
                          }}

                        >Subscription Billing</MenuItem>
                          <MenuItem onClick={async () => {
                          this.props.navigation.navigate("Settings2")
                        }}
                          style={{
                            fontFamily: "Urbanist, sans-serif",
                            display: "flex",
                            flexDirection: 'row',
                            paddingLeft: "30px",
                            fontSize: "14px"
                          }}

                        >Payment</MenuItem>
                      </>
                    }
                    {this.state.profile?.attributes?.role === "instructor" &&
                      <MenuItem onClick={async () => {
                        this.props.navigation.navigate("UpdateAvailability")
                      }} style={{
                        fontFamily: "Urbanist, sans-serif",
                        display: "flex",
                        flexDirection: 'row',
                        paddingLeft: "30px",
                        fontSize: "14px"
                      }}
                      >Availability</MenuItem>
                    }
                    <MenuItem onClick={async () => {
                      this.props.navigation.navigate("ChangePassword")
                    }} style={{
                      fontFamily: "Urbanist, sans-serif",
                      display: "flex",
                      flexDirection: 'row',
                      paddingLeft: "30px",
                      fontSize: "14px"
                    }}
                    >Change Password</MenuItem>
                    <MenuItem
                      style={{
                        fontFamily: "Urbanist, sans-serif",
                        display: "flex",
                        flexDirection: 'row',
                        paddingLeft: "30px",
                        fontSize: "14px"
                      }}
                      onClick={async () => {
                        this.setState({ isDeleteAccountModalOpen: true })
                      }}>Delete Account</MenuItem>
                    <MenuItem
                      style={{
                        fontFamily: "Urbanist, sans-serif",
                        display: "flex",
                        flexDirection: 'row',
                        paddingLeft: "30px",
                        fontSize: "14px"
                      }}
                      onClick={async () => {
                        await removeStorageData('authToken');
                        this.props.navigation.navigate("EmailAccountLogin")
                      }}>Logout</MenuItem>
                  </Menu>
                </ul>
                <img src={require("./menu.png")} className="hamburgerMenu" onClick={() => this.setState({ isOpen: true })} />
              </Nav>
            }
          </Header>
          <div style={{ flex: 1 }}>
            {this.props.children}
            <Drawer
              anchor="right"
              open={this.state.isOpen}
              onClose={() => this.setState({ isOpen: false })}
              PaperProps={{ style: { background: '#2F7735' } }}

            >
              <Box sx={{ mb: 2 }}>
                <ul
                  style={{
                    backgroundColor: "#2F7735",
                    display: "flex",
                    flexDirection: "column",
                    listStyleType: "none",
                    justifyContent: "center",
                    paddingInline: "30px",
                    gap: "20px"
                  }}
                >
                  <span style={{ cursor: "pointer", marginTop: "10px", backgroundColor: "white", borderRadius: "50%", padding: "10px", width: "min-content" }} onClick={() => this.setState({ isOpen: false })}>
                    <img src={require('./close.png')} style={{ width: "10px", height: "10px" }} />
                  </span>
                  <Divider />
                  {this.state.profile &&
                    <li style={{
                      display: "flex", flexDirection: "row", alignItems: "center",
                      gap: "8px"
                    }} onClick={() => { this.setState({ isDropdownDrawerOpen: !this.state.isDropdownDrawerOpen }) }}>
                      <img src={this.state.profile?.attributes?.photo ?? require("./default_avatar.png")} style={{ borderRadius: "50%", width: "32px", height: "32px" }} />
                      <Typography variant="body1" style={{ fontSize: "13px", fontWeight: 600, color: "white" }}>
                        {`${this.state.profile?.attributes?.first_name ?? ""} ${this.state.profile?.attributes?.last_name ?? ""}`}
                      </Typography>
                      <div style={{
                        width: 0,
                        height: 0,
                        borderLeft: "5px solid transparent",
                        borderRight: "5px solid transparent",
                        borderTop: "5px solid white",
                      }} />
                    </li>
                  }
                  {this.state.isDropdownDrawerOpen &&
                    <>
                      <li onClick={async () => {
                        this.props.navigation.navigate("UserProfile")
                      }}
                        style={{
                          cursor: "pointer",
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px",
                          color: "white"
                        }}
                      >Profile</li>
                      <li onClick={async () => {
                        this.props.navigation.navigate("Chat")
                      }}
                        style={{
                          cursor: "pointer",
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px",
                          color: "white"
                        }}
                      >Chats</li>
                      {this.state.profile?.attributes?.role === "instructor" &&
                        <li onClick={async () => {
                          this.props.navigation.navigate("MyVideos")
                        }}
                          style={{
                            cursor: "pointer",
                            fontFamily: "Urbanist, sans-serif",
                            display: "flex",
                            flexDirection: 'row',
                            paddingLeft: "30px",
                            fontSize: "14px",
                            color: "white"
                          }}

                        >My Videos</li>
                      }
                      {this.state.profile?.attributes?.role === "instructor" &&
                        <li onClick={async () => {
                          this.props.navigation.navigate("MyStudents")
                        }}
                          style={{
                            cursor: "pointer",
                            fontFamily: "Urbanist, sans-serif",
                            display: "flex",
                            flexDirection: 'row',
                            paddingLeft: "30px",
                            fontSize: "14px",
                            color: "white"
                          }}

                        >My Students</li>
                      }
                      {this.state.profile?.attributes?.role === "instructor" &&
                     <>   <li onClick={async () => {
                          this.props.navigation.navigate("LessonPackage")
                        }}
                          style={{
                            cursor: "pointer",
                            fontFamily: "Urbanist, sans-serif",
                            display: "flex",
                            flexDirection: 'row',
                            paddingLeft: "30px",
                            fontSize: "14px",
                            color: "white"
                          }}

                        >Lesson Package</li>
                        <li onClick={async () => {
                          this.props.navigation.navigate("Subscriptionbilling2")
                        }}
                          style={{
                            cursor: "pointer",
                            fontFamily: "Urbanist, sans-serif",
                            display: "flex",
                            flexDirection: 'row',
                            paddingLeft: "30px",
                            fontSize: "14px",
                            color: "white"
                          }}

                        >Subscription Billing</li>
                        </>
                      }
                      {this.state.profile?.attributes?.role === "instructor" &&
                        <li onClick={async () => {
                          this.props.navigation.navigate("UpdateAvailability")
                        }} style={{
                          cursor: "pointer",
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px",
                          color: "white"
                        }}
                        >Availability</li>
                      }
                      <li onClick={async () => {
                        this.props.navigation.navigate("ChangePassword")
                      }} style={{
                        cursor: "pointer",
                        fontFamily: "Urbanist, sans-serif",
                        display: "flex",
                        flexDirection: 'row',
                        paddingLeft: "30px",
                        fontSize: "14px",
                        color: "white"
                      }}
                      >Change Password</li>
                      <li
                        style={{
                          cursor: "pointer",
                          fontFamily: "Urbanist, sans-serif",
                          display: "flex",
                          flexDirection: 'row',
                          paddingLeft: "30px",
                          fontSize: "14px",
                          color: "white"
                        }}
                        onClick={async () => {
                          this.setState({ isDeleteAccountModalOpen: true })
                        }}>Delete Account</li>
                    </>
                  }
                  {this.props?.profile?.attributes?.role === "player" && <li><span style={{ fontWeight: this.props.currentPath === "/" ? 700 : 500, textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", }} onClick={() => this.props.navigation?.navigate('Home')}>Home</span></li>}
                  <li><span style={{ textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", fontWeight: this.props.currentPath === "MyLessons" ? 700 : 500}} onClick={() => this.props.navigation?.navigate('MyLessons')}>My Lessons</span></li>
                  <li><span style={{ fontWeight: this.props.currentPath === "MyBookings" ? 700 : 500, textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", }} onClick={() => this.props.navigation?.navigate('MyBookings')}>My Bookings</span></li>
                  <li><span style={{ textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", fontWeight: this.props.currentPath === "ContactUs" ? 700 : 500 }} onClick={() => this.props.navigation?.navigate('ContactUs')}>Contact</span></li>
                  <li><span style={{ fontWeight: this.props.currentPath === "TermsConditionsDetail" ? 700 : 500, textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", }} onClick={() => this.props.navigation?.navigate('TermsConditionsDetail')}>Terms & Conditions</span></li>
                  <li><span style={{ fontWeight: this.props.currentPath === "PrivacyPolicy" ? 700 : 500, textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", }} onClick={() => this.props.navigation?.navigate('PrivacyPolicy')}>Privacy Policy</span></li>
                  {this.state?.profile?.attributes?.role === "instructor" && <li><span style={{ fontWeight: this.props.currentPath === "Reviews" ? 700 : 500, textDecoration: "none", color: "white", cursor: "pointer", fontFamily: "Urbanist, sans-serif", }} onClick={() => this.props.navigation?.navigate('Reviews', {id: this.state.profile.attributes.account_id})}>My Reviews</span></li>}
                  {this.state.profile &&
                    <li style={{ cursor: "pointer", fontFamily: "Urbanist, sans-serif", color: "white", }} onClick={async () => {
                      await removeStorageData('authToken');
                      this.props.navigation.navigate("EmailAccountLogin")
                    }}>Logout</li>
                  }
                </ul>
              </Box>
            </Drawer>
            <Dialog
              style={{
                fontFamily: "Urbanist, sans-serif",
              }}
              open={this.state.isDeleteAccountModalOpen}
              onClose={() => this.setState({ isDeleteAccountModalOpen: false })}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: "22px",
                  textAlign: "center",
                  color: "#333333",
                  marginTop: "20px",
                  fontFamily: "Urbanist, sans-serif",
                }}>
                Delete Account?
              </Typography>
              <DialogContent>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#666666",
                    fontFamily: "Urbanist, sans-serif",
                    maxWidth: "320px",
                  }}>
                  {`Proceed to delete? Confirm to permanently remove your account. Alternatively, cancel to retain your account.`}
                </Typography>
                <Box sx={{ padding: "10px 0px" }}>
                  <TextField
                    style={{
                      backgroundColor: "#F7F8F9",
                      borderRadius: "8px",
                      border: "1px solid #E8ECF4",
                      marginBottom: "2px",
                      height: "56px",
                      width: "100%",
                      marginTop: "5px"
                    }}
                    variant="outlined"
                    data-test-id="txtInputPassword"
                    type={this.state.isPasswordVisible ? "text" : "password"}
                    placeholder={"Enter Password"}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={(e: any) => this.setState({ password: e.target.value })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                            edge="end"
                          >

                            {!this.state.isPasswordVisible ? (
                              <img src={require("./ic_password_invisible.png")} style={{ color: '#6A707C' }} alt="Password Visible" />
                            ) : (
                              <img src={require("./ic_password_visible.png")} style={{ color: '#6A707C' }} alt="Password Invisible" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}

                  />
                  {this.state.passwordError && (
                    <Typography variant="body2" color="error">
                      {this.state.passwordError}
                    </Typography>
                  )}

                </Box>
              </DialogContent>
              <DialogActions style={{ justifyContent: "space-between", padding: "8px 24px", marginBottom: "20px" }}>
                <>
                  <div>
                    <Button
                      style={{
                        border: '1px solid #2F7735',
                        borderRadius: '8px',
                        fontSize: "15px",
                        color: '#2F7735',
                      }} data-test-id='clearBtn'
                      className="clearBtn"
                      onClick={() => this.setState({ isDeleteAccountModalOpen: false })}
                    >Cancel</Button>
                  </div>
                  <div>
                    <Button style={{
                      backgroundColor: '#EB001B',
                      color: '#FFFFFF',
                      fontSize: "15px",
                      borderRadius: '8px',
                      fontWeight: 600,
                      textTransform: "none",
                      border: "unset"
                    }}
                      data-test-id='deleteBtn'
                      className="deleteBtn"
                      onClick={this.onDeleteAccount}
                    >Delete</Button>
                  </div>
                </>
              </DialogActions>
            </Dialog>
          </div>
          <Footer>
            Copyright © 2023 Lync. All rights reserved.
          </Footer>
        </HomeLayoutStyle>
      </ThemeProvider>
    );
  }
}
