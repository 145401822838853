import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type anyType = any;
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import moment, { Moment } from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: anyType;
    coachId: string;
    coachProfile: anyType;
    selectedDate?: Moment;
    selectedTime?: string;
    availability?: anyType;
    step: number;
    isLoading: boolean;
    lessonPackage: any;
    appointment: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    apiGetCoachProfileApiCallId = ""
    apiGetAvailabilityApiCallId = ""
    apiBookSlotApiCallId = ""
    apiGetLessonPackageApiCallId = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        const pathname = typeof window !== "undefined" ? window.location.pathname : 'localhost';

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            profile: undefined,
            coachId: this.props.navigation.getParam("id"),
            coachProfile: undefined,
            selectedDate: moment(),
            availability: [],
            selectedTime: undefined,
            step: pathname.includes("Confirm") ? 3 : 1,
            isLoading: false,
            lessonPackage: undefined,
            appointment: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start  
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponseMessage(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
 convertTo24HourTime = (timeStr: any)  => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":");

    if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
    }
    if (modifier === "AM" && hours === "12") {
        hours = "00";
    }

    return `${String(hours).padStart(2, '0')}:${minutes}`;
}

 filterPastTimes = (times: any, selectedDate: any) => {
    const now = moment();
    const selected = moment(selectedDate, "YYYY-MM-DD");  
    if (now.isSame(selected, 'day')) {
        const currentTime = now.hours() * 60 + now.minutes();
        return times?.filter((timeStr: any) => {
            const [hours, minutes] = this.convertTo24HourTime(timeStr).split(":");
            const timeInMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
            return timeInMinutes > currentTime;
        });
    }
    return times;
}

    convertToUserTime = (data: any) => {
        if(!data) return [];
    
        const timeZone = moment.tz.guess();
        const groups: any = {};
    
        data.forEach((item: any) => {
          const availability_date = item.availability_date;
          item.time_slots.forEach((slot: any) => {
            const utc = `${availability_date} ${slot}`;
            const localTime = moment.tz(utc, "YYYY-MM-DD hh:mm A", "UTC").tz(timeZone).format("hh:mm A");
            const newAvailibilityDate = moment.tz(utc, "YYYY-MM-DD hh:mm A", "UTC").tz(timeZone).format("YYYY-MM-DD");
    
            if (!groups[newAvailibilityDate]) {
              groups[newAvailibilityDate] = {
                availability_date: newAvailibilityDate,
                time_slots: []
              };
            }
    
            groups[newAvailibilityDate].time_slots.push(localTime);
          });
        });
    
        return Object.values(groups);
    
      };
    
    handleAPIResponseMessage(message: Message) {
        const requestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const errorsResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (requestId === this.apiGetCoachProfileApiCallId) {
            this.handleGetCoachProfileCallId(responseData, errorsResponse);
        }

        if (requestId === this.apiGetLessonPackageApiCallId) {
            this.handleGetLessonPackage(responseData, errorsResponse);
        }

        if (requestId === this.apiGetAvailabilityApiCallId) {
            this.handleGetAvailabilityCallId(responseData, errorsResponse);
        }
        if (requestId === this.apiBookSlotApiCallId) {
            this.setState({isLoading: false});

            if (responseData?.errors?.length > 0) {
                toast.error(responseData?.errors?.[0])
            }
            if (responseData?.data) {
                this.setState({ step: 3 });
            }
        }
    }

    onSelectTime = (time: string) => () => {
        const foundIdx = this.state.appointment.findIndex((e: any) => e.date === this.state.selectedDate);
        const lessonAmount = Number(this.state.lessonPackage?.lesson_name?.[0] ?? 1);

        if (foundIdx >= 0) {
            if (this.state.appointment[foundIdx].time_slot === time) {
                const newAppointment = [...this.state.appointment]
                newAppointment.splice(foundIdx, 1);
                this.setState({appointment: newAppointment})
            } else {
                const newAppointment = [...this.state.appointment]
                newAppointment[foundIdx].time_slot = time;
                this.setState({appointment: newAppointment})
            }
        } else {
            if (this.state.appointment.length >= lessonAmount) return; 
            
            this.setState({appointment: this.state.appointment.concat([{
                "date": this.state.selectedDate,
                "time_slot": time,
                "duration": 30
            }])})
        }
    }

    sortArrayByTime = (arr: string[]) => {
        if (!arr) return [];

        arr.sort((a, b) => {
            const timeA = new Date(`2000-01-01 ${a}`);
            const timeB = new Date(`2000-01-01 ${b}`);
            return timeA.getTime() - timeB.getTime();
        });

        return arr;
    }

    handleGetAvailabilityCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
      const data = this.convertToUserTime(responseJson.data.map((item: any) => ({ availability_date: item.attributes.availability_date, time_slots: item.attributes.time_slots })))
          this.setState({ availability: data })
        }
      }

      handleGetLessonPackage(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
          this.setState({ lessonPackage: responseJson.data.find((e: any) => e.id === this.props.navigation.getParam("packageId"))?.attributes })
        }
    }

    getInstructorAvailability = (token: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            "Content-Type": configJSON.contentTypeApiGetInstructorAvailabilities,
            token: token
        };

        this.apiGetAvailabilityApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetInstructorAvailabilities}/${this.props.navigation.getParam('id')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetInstructorAvailabilities
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    onNext = () => {
        this.setState({ step: 2 });
    }

    onConfirm = () => {
      this.props.navigation.navigate("PlayerStripePayments", {id: this.props.navigation.getParam("packageId")});
      const body = {
        "account_id": this.props.navigation.getParam("id"),
        "appointments": this.state.appointment.map((value: any) => {
            const dateTimeInLocal = moment(`${value.date?.format('YYYY-MM-DD')} ${value.time_slot}`, 'YYYY-MM-DD hh:mm A');
            const dateTimeInUTC = dateTimeInLocal.utc();
       
            return {
                ...value,
                "date": dateTimeInUTC.format("YYYY-MM-DD"),
                "time_slot": dateTimeInUTC.format("hh:mm A"),
            }
        }),
      }
      // None sensitive data
      localStorage.setItem("noneSensitiveDataBooking", JSON.stringify(body));
    }

    handleGetCoachProfileCallId(responseJson: anyType, errorResponse: anyType) {
        if (responseJson && responseJson.data) {
            this.setState({ coachProfile: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    onChangeDate = (date: Moment) => {
        this.setState({ selectedDate: date })
    }

    textRangeGenerator = (inputText?: string) => {
        if (!inputText) return "";
    
        const startTime = moment(inputText, "h:mm A");
    
        const endTime = moment(startTime).add(30, 'minutes');
    
        const formattedStartTime = startTime.format("h:mm");
        const formattedEndTime = endTime.format("h:mm");
    
        const period = endTime.format("A").toLowerCase();
    
        return `${formattedStartTime}-${formattedEndTime} ${period}`;
    };
    

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        console.log("appointment",this.state.appointment)
        if (!prevState.selectedDate?.isSame(this.state.selectedDate)) {
            try {
                const token = await getStorageData('authToken');
                if (token) {
                    this.setState({ selectedTime: undefined })
                    this.getInstructorAvailability(token);
                }
            } catch { }
        }
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getCoachProfile(token);
                this.getPlayerLessonPacakge(token);
                this.getInstructorAvailability(token);
            } else this.props.navigation.navigate("EmailAccountLogin");
        } catch { }
    }

    getCoachProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetCoachProfile,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetCoachProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetCoachProfile
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetCoachProfile}?id=${this.props.navigation.getParam("id")}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    bookSlot = async () => {
        this.setState({isLoading: true});
        
        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": configJSON.contentTypeApiBookedSlot,
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiBookSlotApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiBookedSlot
        );

        const localDateTime = this.state.selectedDate;
        const localTimeSlot = this.state.selectedTime;
        
        const dateTimeInLocal = moment(`${localDateTime?.format('YYYY-MM-DD')} ${localTimeSlot}`, 'YYYY-MM-DD hh:mm A');
        const dateTimeInUTC = dateTimeInLocal.utc();
        
        const httpBody = {
            "account_id": this.props.navigation.getParam("id"),
            "date": dateTimeInUTC.format("YYYY-MM-DD"),
            "time_slot": dateTimeInUTC.format("hh:mm A"),
            "duration": 30
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiBookedSlot}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getPlayerLessonPacakge = (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetLessonPackageApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_inventory_management/lesson_packages/instructor_lesson_packages?account_id=${this.props.navigation.getParam("id")}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    onGoToBooking = () => {
        this.props.navigation.navigate("MyBookings")
    }
    // Customizable Area End
}